<template>
  <div class="project-left-tab">
    <div class="flex items-center">
      <div class="project-img m-r-sm">
        <img v-if="projeto.image" width="25" class="" :src="projeto.image"/>
        <div v-else class="img-fake">{{ firstLettersName(projeto.name) }}</div>
      </div>
      <div>
        <h3 class="title">{{projeto.name}}</h3>
        <div class="subtitle" v-if="projeto.type">{{projeto.type.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectTab",
  props: ['projeto'],
  mounted() {
    const left = document.getElementById('suporte-page-left')
    left.querySelector('.component-opts').append(this.$el)
  },
  beforeDestroy() {
    const left = document.getElementById('suporte-page-left')
    if (left) {
      const node = left.querySelector('.component-opts')
      node && node.contains(this.$el) && node.removeChild(this.$el)
    }
  }
}
</script>
