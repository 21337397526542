export const REPETIR_DIA = 1
export const REPETIR_SEMANAL = 2
export const REPETIR_MENSAL = 3
export const REPETIR_BIMESTRAL = 4
export const REPETIR_TRIMESTRAL = 5
export const REPETIR_SEMESTRAL = 6
export const REPETIR_ANUAL = 7

export const REPETIR = {
    [REPETIR_DIA]: {title: 'Diariamente'},
    [REPETIR_SEMANAL]: {title: 'Semanal'},
    [REPETIR_MENSAL]: {title: 'Mensal'},
    [REPETIR_BIMESTRAL]: {title: 'Bimestral'},
    [REPETIR_TRIMESTRAL]: {title: 'Trimestral'},
    [REPETIR_SEMESTRAL]: {title: 'Semestral'},
    [REPETIR_ANUAL]: {title: 'Anual'},
}

export const PERMISSION_VISIBLE = 0
export const PERMISSION_ONLY_MEMBERS = 1

export const PERMISSIONS = {
    [PERMISSION_VISIBLE]: {title: 'Visível para usuários', min: 'VisU'},
    [PERMISSION_ONLY_MEMBERS]: {title: 'Privado, somente membros', min: 'Priv'}
}

export const FILA_PRIORITY_NORMAL = 0
export const FILA_PRIORITY_MEDIUM = 1
export const FILA_PRIORITY_HIGH = 2
export const FILA_PRIORITY_CRITICAL = 99

export const FILA_PRIORITY = {
    [FILA_PRIORITY_NORMAL]: {title: 'Normal'},
    [FILA_PRIORITY_MEDIUM]: {title: 'Média'},
    [FILA_PRIORITY_HIGH]: {title: 'Alta'},
    [FILA_PRIORITY_CRITICAL]: {title: 'Crítico/Imediato'}
}

export const TASK_OPENED = 'opened'
export const TASK_ASSIGNEDME = 'assignedMe'
export const TASK_UNASSIGNED = 'unassigned'
export const TASK_DUE = 'due'
export const TASK_CLOSED = 'closed'

export const TASK_FIXED_QUEUE = {
    [TASK_OPENED]: {title: 'Em aberto'},
    [TASK_ASSIGNEDME]: {title: 'Atribuídos a mim'},
    [TASK_UNASSIGNED]: {title: 'Não atribuídos'},
    [TASK_DUE]: {title: 'Atrasadas'},
    [TASK_CLOSED]: {title: 'Fechadas'},
}
