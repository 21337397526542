<script>
import {findProject} from "@/domain/project/services"
import SlLoading from "components/layout/components/Loading"
import ProjectTab from "components/suporte/components/projetos/include/ProjectTab"
import MenuProjeto from "components/suporte/components/projetos/include/MenuProjeto"
import {TASK_FIXED_QUEUE} from "@/domain/project/helpers/types"

export default {
  name: 'SuporteProjetoPage',
  provide: function () {
    return {
      project: this,
      page: this,
      container: this
    }
  },
  mixins: [],
  components: {
    MenuProjeto,
    ProjectTab,
    SlLoading
  },
  data () {
    return {
      routeName: 'suporte.projetos.projeto',
      /*menuActive: {
        name: 'opened',
        label: 'Em aberto',
      },*/
      menuActive: {},
      loading: true,
      projeto: null
    }
  },
  mounted() {
    this.load()
    if (this.$route.name === this.routeName) {
      this.menuActive = {
        name: 'opened',
        label: 'Em aberto',
      }
    }
  },
  computed: {
  },
  methods: {
    load() {
      this.loading = true
      findProject(this.$route.params.id)
          .then(response => {
            this.projeto = response.data
            if (this.$route.params.fila) {
              if (isNaN(Number(this.$route.params.fila))) {
                if (typeof this.projeto._fixedQueues[this.$route.params.fila] !== 'undefined') {
                  this.menuActive = {
                    name: this.$route.params.fila,
                    label: TASK_FIXED_QUEUE[this.$route.params.fila].title
                  }
                }
              } else if (this.projeto._queues) {
                const fila = this.projeto._queues.find(q => Number(q.id) === Number(this.$route.params.fila))
                if (fila) {
                  this.menuActive = {
                    name: 'id:' + fila.id,
                    label: fila.name
                  }
                }
              }
            }
            setTimeout(() => {
              this.$options.meta.title = this.projeto.name + ' - Projetos'
            }, 2000)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    loadQueue (queue) {
      console.log('Load queue: ', queue)
      this.$router.push({name: 'suporte.projetos.projeto.fila', params: {id: this.projeto.id, fila: queue.replace('id:', '')}})
    }
  },
  meta: {
    title: 'Projeto',
    name: 'Projeto'
  }
}
</script>

<template>
  <div v-if="loading" class="scontent">
    <sl-loading />
  </div>
  <div v-else class="scontent">
    <div style="height: 0; max-height: 0">
      <menu-projeto ref="menuProjeto" />
      <project-tab :projeto="projeto" />
    </div>
    <router-view />
  </div>
</template>
